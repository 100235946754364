import revive_payload_client_sUGtUTVM0M from "/home/ecomadm/app/releases/64/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@9.7.0_rollup@4.24.0_typescript@5.5.4_vite@5.4.8/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_uKE0yeC5Oo from "/home/ecomadm/app/releases/64/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@9.7.0_rollup@4.24.0_typescript@5.5.4_vite@5.4.8/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_iMrTxeUW9L from "/home/ecomadm/app/releases/64/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@9.7.0_rollup@4.24.0_typescript@5.5.4_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_CYSBTgH7ML from "/home/ecomadm/app/releases/64/node_modules/.pnpm/nuxt-site-config@2.2.15_rollup@4.24.0_vite@5.4.8_vue@3.5.12/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_GnpKXzgwdm from "/home/ecomadm/app/releases/64/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@9.7.0_rollup@4.24.0_typescript@5.5.4_vite@5.4.8/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_kNpjX0DJCd from "/home/ecomadm/app/releases/64/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@9.7.0_rollup@4.24.0_typescript@5.5.4_vite@5.4.8/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_P07IriARTr from "/home/ecomadm/app/releases/64/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@9.7.0_rollup@4.24.0_typescript@5.5.4_vite@5.4.8/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_npKQUAjV9f from "/home/ecomadm/app/releases/64/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@9.7.0_rollup@4.24.0_typescript@5.5.4_vite@5.4.8/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_ENnv0xTZ6L from "/home/ecomadm/app/releases/64/node_modules/.pnpm/@pinia+nuxt@0.5.5_rollup@4.24.0_typescript@5.5.4_vue@3.5.12/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/ecomadm/app/releases/64/.nuxt/components.plugin.mjs";
import prefetch_client_Zk9LgbH8H3 from "/home/ecomadm/app/releases/64/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@20.14.12_eslint@9.7.0_rollup@4.24.0_typescript@5.5.4_vite@5.4.8/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import pwa_icons_ZFm9nQtzbO from "/home/ecomadm/app/releases/64/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_rollup@4.24.0_vite@5.4.8_workbox-build@7.1.0_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa-icons.mjs";
import pwa_client_FSCOMND7Dy from "/home/ecomadm/app/releases/64/node_modules/.pnpm/@vite-pwa+nuxt@0.7.0_rollup@4.24.0_vite@5.4.8_workbox-build@7.1.0_workbox-window@7.1.0/node_modules/@vite-pwa/nuxt/dist/runtime/plugins/pwa.client.mjs";
import i18n_HZQJygCI5i from "/home/ecomadm/app/releases/64/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.24.0_vue@3.5.12/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import plugin_EvwCApL3vp from "/home/ecomadm/app/releases/64/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.5.12/node_modules/@t3headless/nuxt-typo3/dist/runtime/plugin.mjs";
import plugin_WJP4IXCWmD from "/home/ecomadm/app/releases/64/node_modules/.pnpm/nuxt-graphql-client@0.2.36_graphql-tag@2.12.6/node_modules/nuxt-graphql-client/dist/runtime/plugin.mjs";
import plugin_IxSNCaBEU4 from "/home/ecomadm/app/releases/64/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.5_pinia@2.2.4_rollup@4.24.0/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import rawlplug_ui_6DoqzJ8zlH from "/home/ecomadm/app/releases/64/modules/rawlplug-ui/plugins/rawlplug-ui.ts";
import virtual_scroller_j0vDvJKkJH from "/home/ecomadm/app/releases/64/modules/rawlplug-ui/plugins/virtual-scroller.ts";
import acl_t5sS6lHB6p from "/home/ecomadm/app/releases/64/modules/rawlplug-customer/plugins/acl.ts";
import authRedirect_loUuHfcIX1 from "/home/ecomadm/app/releases/64/modules/rawlplug-customer/plugins/authRedirect.ts";
import sectionData_vsvWj0CJ7c from "/home/ecomadm/app/releases/64/modules/rawlplug-customer/plugins/sectionData.ts";
import setHeaders_3PDyKigfaa from "/home/ecomadm/app/releases/64/modules/rawlplug-customer/plugins/setHeaders.ts";
import watchCustomerSession_client_OINYrv7Nny from "/home/ecomadm/app/releases/64/modules/rawlplug-customer/plugins/watchCustomerSession.client.ts";
import customerCreditHold_uaOaR07KmZ from "/home/ecomadm/app/releases/64/modules/rawlplug-ecom/plugins/customerCreditHold.ts";
import magentoRestApi_Rxph9rYfJy from "/home/ecomadm/app/releases/64/modules/rawlplug-ecom/plugins/magentoRestApi.ts";
import onGqlErrors_cdhJBIAsKr from "/home/ecomadm/app/releases/64/modules/rawlplug-ecom/plugins/onGqlErrors.ts";
import parseGqlError_80UVNGDPbe from "/home/ecomadm/app/releases/64/modules/rawlplug-ecom/plugins/parseGqlError.ts";
import setHeadTags_TuBvNAMaiT from "/home/ecomadm/app/releases/64/modules/rawlplug-ecom/plugins/setHeadTags.ts";
import veeValidateEcommerce_2k9th3UBvH from "/home/ecomadm/app/releases/64/modules/rawlplug-ecom/plugins/veeValidateEcommerce.ts";
import nuxt_typo3_hooks_YEMuImgsK0 from "/home/ecomadm/app/releases/64/modules/rawlplug-cms/plugins/nuxt-typo3-hooks.ts";
import chunkError_client_orW7FAK9A7 from "/home/ecomadm/app/releases/64/modules/rawlplug-base/plugins/chunkError.client.ts";
import fixIosInputZoom_sxKROb73VY from "/home/ecomadm/app/releases/64/modules/rawlplug-base/plugins/fixIosInputZoom.ts";
import maintenanceMode_client_of4DxOt2sw from "/home/ecomadm/app/releases/64/modules/rawlplug-base/plugins/maintenanceMode.client.ts";
import mediaData_UTgroMKJHM from "/home/ecomadm/app/releases/64/modules/rawlplug-base/plugins/mediaData.ts";
import piwik_JAi7Zj0BOi from "/home/ecomadm/app/releases/64/modules/rawlplug-base/plugins/piwik.ts";
import sentry_client_7cIuQv2GUF from "/home/ecomadm/app/releases/64/modules/rawlplug-base/plugins/sentry.client.ts";
import veeValidate_rMbef5NodW from "/home/ecomadm/app/releases/64/modules/rawlplug-base/plugins/veeValidate.ts";
export default [
  revive_payload_client_sUGtUTVM0M,
  unhead_uKE0yeC5Oo,
  router_iMrTxeUW9L,
  _0_siteConfig_CYSBTgH7ML,
  payload_client_GnpKXzgwdm,
  navigation_repaint_client_kNpjX0DJCd,
  check_outdated_build_client_P07IriARTr,
  chunk_reload_client_npKQUAjV9f,
  plugin_vue3_ENnv0xTZ6L,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Zk9LgbH8H3,
  pwa_icons_ZFm9nQtzbO,
  pwa_client_FSCOMND7Dy,
  i18n_HZQJygCI5i,
  plugin_EvwCApL3vp,
  plugin_WJP4IXCWmD,
  plugin_IxSNCaBEU4,
  rawlplug_ui_6DoqzJ8zlH,
  virtual_scroller_j0vDvJKkJH,
  acl_t5sS6lHB6p,
  authRedirect_loUuHfcIX1,
  sectionData_vsvWj0CJ7c,
  setHeaders_3PDyKigfaa,
  watchCustomerSession_client_OINYrv7Nny,
  customerCreditHold_uaOaR07KmZ,
  magentoRestApi_Rxph9rYfJy,
  onGqlErrors_cdhJBIAsKr,
  parseGqlError_80UVNGDPbe,
  setHeadTags_TuBvNAMaiT,
  veeValidateEcommerce_2k9th3UBvH,
  nuxt_typo3_hooks_YEMuImgsK0,
  chunkError_client_orW7FAK9A7,
  fixIosInputZoom_sxKROb73VY,
  maintenanceMode_client_of4DxOt2sw,
  mediaData_UTgroMKJHM,
  piwik_JAi7Zj0BOi,
  sentry_client_7cIuQv2GUF,
  veeValidate_rMbef5NodW
]