import { default as _91_46_46_46slug_93kJsdkiLFrpMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-cms/pages/[...slug].vue?macro=true";
import { default as addressesGIFfUNmu2WMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/addresses.vue?macro=true";
import { default as companyhUh320nqV6Meta } from "/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/company.vue?macro=true";
import { default as contacthdtRYE4yDjMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/contact.vue?macro=true";
import { default as indexEATRsQiepFMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/index.vue?macro=true";
import { default as listsGUojJK9dn3Meta } from "/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/lists.vue?macro=true";
import { default as _91variantId_93CkGP8JAHJpMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/offer/[id]/[variantId].vue?macro=true";
import { default as indexmLwIJkIpI9Meta } from "/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/offers/index.vue?macro=true";
import { default as _91_46_46_46slug_93ykSDmrb7VpMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/order/[...slug].vue?macro=true";
import { default as orders6DXrpxPESAMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/orders.vue?macro=true";
import { default as payments_45and_45billingFoK7qfh79iMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/payments-and-billing.vue?macro=true";
import { default as add_45userf2MmVGXO4PMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/permissions/add-user.vue?macro=true";
import { default as editiP4e8ElXWXMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/[id]/edit.vue?macro=true";
import { default as indexcl2Ms2CsHoMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/index.vue?macro=true";
import { default as newYSvc1vCD6GMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/new.vue?macro=true";
import { default as editORzpLyExB8Meta } from "/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/permissions/users/[id]/edit.vue?macro=true";
import { default as indexPMOmn0MvgWMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/permissions/users/index.vue?macro=true";
import { default as profilexnJCbDizDvMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/profile.vue?macro=true";
import { default as account5zYvFUKN2kMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account.vue?macro=true";
import { default as _91slug_93WKdZyKSGoyMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-ecom/pages/[locale]/category/[slug].vue?macro=true";
import { default as categoryJkByNXZy13Meta } from "/home/ecomadm/app/releases/64/modules/rawlplug-ecom/pages/[locale]/category.vue?macro=true";
import { default as cartplbzmRdRPYMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-ecom/pages/[locale]/checkout/cart.vue?macro=true";
import { default as delivery_45and_45paymentwHuwd7jeihMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-ecom/pages/[locale]/checkout/delivery-and-payment.vue?macro=true";
import { default as summaryWMexYqMKI7Meta } from "/home/ecomadm/app/releases/64/modules/rawlplug-ecom/pages/[locale]/checkout/summary.vue?macro=true";
import { default as checkoutESYPSbjIgEMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-ecom/pages/[locale]/checkout.vue?macro=true";
import { default as createPasswordrOXKqRa5veMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/customer/account/createPassword.vue?macro=true";
import { default as resetPasswordijklPRIGXfMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/customer/account/resetPassword.vue?macro=true";
import { default as indexfTGXlB1GjtMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/event/index.vue?macro=true";
import { default as indexWsQ8nlDLBrMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/newsletter/index.vue?macro=true";
import { default as productv42UF5yrXTMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-ecom/pages/[locale]/product.vue?macro=true";
import { default as registerHLsz2zhrNLMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/register.vue?macro=true";
import { default as resolveyYG2I3xlSCMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-ecom/pages/[locale]/resolve.vue?macro=true";
import { default as cmsfKeYQlkI6bMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-ecom/pages/[locale]/search/cms.vue?macro=true";
import { default as storeEQpXydLaVtMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-ecom/pages/[locale]/search/store.vue?macro=true";
import { default as searchGDBUpYqYJ1Meta } from "/home/ecomadm/app/releases/64/modules/rawlplug-ecom/pages/[locale]/search.vue?macro=true";
import { default as DescriptionSectionBJT73UsQKfMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/DescriptionSection.vue?macro=true";
import { default as HeroSection7plGb9hZPNMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/HeroSection.vue?macro=true";
import { default as indexqbpONV1cYnMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/technical-helpdesk/index.vue?macro=true";
import { default as ChooseClientsqHuXLuIIKMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClient.vue?macro=true";
import { default as ChooseClientElement7XItiTsYJmMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement.vue?macro=true";
import { default as ClientsElement2uBkgMzR5FMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsElement/ClientsElement.vue?macro=true";
import { default as ClientsSectionql7FwzzJZWMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsSection.vue?macro=true";
import { default as HeroHeaderm6vPsM5qFbMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/HeroHeader/HeroHeader.vue?macro=true";
import { default as goToJoinRawlplugSectionIJ1gh5SlbKMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/goToJoinRawlplugSection.ts?macro=true";
import { default as JoinRawlplugWXc7Au721FMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplug.vue?macro=true";
import { default as JoinRawlplugFormaADqbszsNyMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugForm.vue?macro=true";
import { default as JoinRawlplugModalfgQWt5wNbxMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugModal.vue?macro=true";
import { default as JoinRawlplugSticky5Ez4dkMtNUMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugSticky.vue?macro=true";
import { default as SlideCta1WYcn6hBLwMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideCta.vue?macro=true";
import { default as SlideLefttBHYCUjIgtMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideLeft.vue?macro=true";
import { default as SlideRightR2uK7ya8a7Meta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideRight.vue?macro=true";
import { default as SliderPaginationwgjSirYSWWMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderPagination.vue?macro=true";
import { default as SliderSectionva5s69cKQGMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderSection.vue?macro=true";
import { default as slidesrnNOZdX62TMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/slider/slides.ts?macro=true";
import { default as disableAutoScrollOnLastSectionxsL6Z0bJ6SMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/composables/disableAutoScrollOnLastSection.ts?macro=true";
import { default as usePagination4q6PbCrawbMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/composables/usePagination.ts?macro=true";
import { default as useStyleWithHeaderHeight7WdyNCItf3Meta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/composables/useStyleWithHeaderHeight.ts?macro=true";
import { default as indexRwrHWcvCmbMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/index.vue?macro=true";
import { default as constantsvIKKHAJv7RMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/constants.js?macro=true";
import { default as FullPagesAE25J8X5nMeta } from "/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/FullPage.vue?macro=true";
import { default as T3PagezBtunixiBNMeta } from "/home/ecomadm/app/releases/64/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.5.12/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    meta: _91_46_46_46slug_93kJsdkiLFrpMeta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-cms/pages/[...slug].vue")
  },
  {
    name: "account",
    path: "/:locale()/account",
    meta: account5zYvFUKN2kMeta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account.vue"),
    children: [
  {
    name: addressesGIFfUNmu2WMeta?.name ?? "locale-account-addresses",
    path: "addresses",
    meta: addressesGIFfUNmu2WMeta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/addresses.vue")
  },
  {
    name: companyhUh320nqV6Meta?.name ?? "locale-account-company",
    path: "company",
    meta: companyhUh320nqV6Meta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/company.vue")
  },
  {
    name: contacthdtRYE4yDjMeta?.name ?? "locale-account-contact",
    path: "contact",
    meta: contacthdtRYE4yDjMeta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/contact.vue")
  },
  {
    name: "locale-account",
    path: "",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/index.vue")
  },
  {
    name: listsGUojJK9dn3Meta?.name ?? "locale-account-lists",
    path: "lists",
    meta: listsGUojJK9dn3Meta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/lists.vue")
  },
  {
    name: "offer",
    path: "offer/:id/:variantId",
    meta: _91variantId_93CkGP8JAHJpMeta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/offer/[id]/[variantId].vue")
  },
  {
    name: indexmLwIJkIpI9Meta?.name ?? "locale-account-offers",
    path: "offers",
    meta: indexmLwIJkIpI9Meta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/offers/index.vue")
  },
  {
    name: "locale-account-order-slug",
    path: "order/:slug+",
    meta: _91_46_46_46slug_93ykSDmrb7VpMeta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/order/[...slug].vue")
  },
  {
    name: orders6DXrpxPESAMeta?.name ?? "locale-account-orders",
    path: "orders",
    meta: orders6DXrpxPESAMeta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/orders.vue")
  },
  {
    name: payments_45and_45billingFoK7qfh79iMeta?.name ?? "locale-account-payments-and-billing",
    path: "payments-and-billing",
    meta: payments_45and_45billingFoK7qfh79iMeta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/payments-and-billing.vue")
  },
  {
    name: "locale-account-permissions-add-user",
    path: "permissions/add-user",
    meta: add_45userf2MmVGXO4PMeta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/permissions/add-user.vue")
  },
  {
    name: "locale-account-permissions-roles-id-edit",
    path: "permissions/roles/:id()/edit",
    meta: editiP4e8ElXWXMeta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/[id]/edit.vue")
  },
  {
    name: "locale-account-permissions-roles",
    path: "permissions/roles",
    meta: indexcl2Ms2CsHoMeta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/index.vue")
  },
  {
    name: "locale-account-permissions-roles-new",
    path: "permissions/roles/new",
    meta: newYSvc1vCD6GMeta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/permissions/roles/new.vue")
  },
  {
    name: "locale-account-permissions-users-id-edit",
    path: "permissions/users/:id()/edit",
    meta: editORzpLyExB8Meta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/permissions/users/[id]/edit.vue")
  },
  {
    name: indexPMOmn0MvgWMeta?.name ?? "locale-account-permissions-users",
    path: "permissions/users",
    meta: indexPMOmn0MvgWMeta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/permissions/users/index.vue")
  },
  {
    name: profilexnJCbDizDvMeta?.name ?? "locale-account-profile",
    path: "profile",
    meta: profilexnJCbDizDvMeta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/account/profile.vue")
  }
]
  },
  {
    name: "category",
    path: "/:path/c/",
    meta: categoryJkByNXZy13Meta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-ecom/pages/[locale]/category.vue"),
    children: [
  {
    name: "category",
    path: ":slug+",
    meta: _91slug_93WKdZyKSGoyMeta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-ecom/pages/[locale]/category/[slug].vue")
  }
]
  },
  {
    name: checkoutESYPSbjIgEMeta?.name ?? "locale-checkout",
    path: "/:path/checkout/",
    meta: checkoutESYPSbjIgEMeta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-ecom/pages/[locale]/checkout.vue"),
    children: [
  {
    name: cartplbzmRdRPYMeta?.name ?? "locale-checkout-cart",
    path: "/:path/checkout/cart",
    meta: cartplbzmRdRPYMeta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-ecom/pages/[locale]/checkout/cart.vue")
  },
  {
    name: "checkout-delivery-and-payment",
    path: "/:path/checkout/delivery-and-payment",
    meta: delivery_45and_45paymentwHuwd7jeihMeta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-ecom/pages/[locale]/checkout/delivery-and-payment.vue")
  },
  {
    name: "checkout-summary",
    path: "/:path/checkout/summary",
    meta: summaryWMexYqMKI7Meta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-ecom/pages/[locale]/checkout/summary.vue")
  }
]
  },
  {
    name: "locale-customer-account-createPassword",
    path: "/:locale()/customer/account/createPassword",
    meta: createPasswordrOXKqRa5veMeta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/customer/account/createPassword.vue")
  },
  {
    name: "locale-customer-account-resetPassword",
    path: "/:locale()/customer/account/resetPassword",
    meta: resetPasswordijklPRIGXfMeta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/customer/account/resetPassword.vue")
  },
  {
    name: "locale-event",
    path: "/:locale()/event",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/event/index.vue")
  },
  {
    name: "locale-newsletter",
    path: "/:locale()/newsletter",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/newsletter/index.vue")
  },
  {
    name: "product",
    path: "/:path/p/:slug",
    meta: productv42UF5yrXTMeta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-ecom/pages/[locale]/product.vue")
  },
  {
    name: registerHLsz2zhrNLMeta?.name ?? "locale-register",
    path: "/:locale()/register",
    meta: registerHLsz2zhrNLMeta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-customer/pages/[locale]/register.vue")
  },
  {
    name: "ecom-redirect-resolve",
    path: "/:locale/r/:type/:slug",
    meta: resolveyYG2I3xlSCMeta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-ecom/pages/[locale]/resolve.vue")
  },
  {
    name: searchGDBUpYqYJ1Meta?.name ?? "locale-search",
    path: "/:path/search",
    meta: searchGDBUpYqYJ1Meta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-ecom/pages/[locale]/search.vue"),
    children: [
  {
    name: cmsfKeYQlkI6bMeta?.name ?? "locale-search-cms",
    path: "/:path/search/cms",
    meta: cmsfKeYQlkI6bMeta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-ecom/pages/[locale]/search/cms.vue")
  },
  {
    name: storeEQpXydLaVtMeta?.name ?? "locale-search-store",
    path: "/:path/search/store",
    meta: storeEQpXydLaVtMeta || {},
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-ecom/pages/[locale]/search/store.vue")
  }
]
  },
  {
    name: "locale-technical-helpdesk-components-DescriptionSection",
    path: "/:locale()/technical-helpdesk/components/DescriptionSection",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/DescriptionSection.vue")
  },
  {
    name: "locale-technical-helpdesk-components-HeroSection",
    path: "/:locale()/technical-helpdesk/components/HeroSection",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/technical-helpdesk/components/HeroSection.vue")
  },
  {
    name: "locale-technical-helpdesk",
    path: "/:locale()/technical-helpdesk",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/technical-helpdesk/index.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ChooseClient-ChooseClient",
    path: "/:locale()/welcome/components/ClientsSection/ChooseClient/ChooseClient",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClient.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ChooseClient-ChooseClientElement-ChooseClientElement",
    path: "/:locale()/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ChooseClient/ChooseClientElement/ChooseClientElement.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ClientsElement-ClientsElement",
    path: "/:locale()/welcome/components/ClientsSection/ClientsElement/ClientsElement",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsElement/ClientsElement.vue")
  },
  {
    name: "locale-welcome-components-ClientsSection-ClientsSection",
    path: "/:locale()/welcome/components/ClientsSection/ClientsSection",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/ClientsSection/ClientsSection.vue")
  },
  {
    name: "locale-welcome-components-HeroHeader-HeroHeader",
    path: "/:locale()/welcome/components/HeroHeader/HeroHeader",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/HeroHeader/HeroHeader.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-goToJoinRawlplugSection",
    path: "/:locale()/welcome/components/JoinRawlplug/goToJoinRawlplugSection",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/goToJoinRawlplugSection.ts")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplug",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplug",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplug.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugForm",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugForm",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugForm.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugModal",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugModal",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugModal.vue")
  },
  {
    name: "locale-welcome-components-JoinRawlplug-JoinRawlplugSticky",
    path: "/:locale()/welcome/components/JoinRawlplug/JoinRawlplugSticky",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/JoinRawlplug/JoinRawlplugSticky.vue")
  },
  {
    name: "locale-welcome-components-slider-SlideCta",
    path: "/:locale()/welcome/components/slider/SlideCta",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideCta.vue")
  },
  {
    name: "locale-welcome-components-slider-SlideLeft",
    path: "/:locale()/welcome/components/slider/SlideLeft",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideLeft.vue")
  },
  {
    name: "locale-welcome-components-slider-SlideRight",
    path: "/:locale()/welcome/components/slider/SlideRight",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SlideRight.vue")
  },
  {
    name: "locale-welcome-components-slider-SliderPagination",
    path: "/:locale()/welcome/components/slider/SliderPagination",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderPagination.vue")
  },
  {
    name: "locale-welcome-components-slider-SliderSection",
    path: "/:locale()/welcome/components/slider/SliderSection",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/slider/SliderSection.vue")
  },
  {
    name: "locale-welcome-components-slider-slides",
    path: "/:locale()/welcome/components/slider/slides",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/components/slider/slides.ts")
  },
  {
    name: "locale-welcome-composables-disableAutoScrollOnLastSection",
    path: "/:locale()/welcome/composables/disableAutoScrollOnLastSection",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/composables/disableAutoScrollOnLastSection.ts")
  },
  {
    name: "locale-welcome-composables-usePagination",
    path: "/:locale()/welcome/composables/usePagination",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/composables/usePagination.ts")
  },
  {
    name: "locale-welcome-composables-useStyleWithHeaderHeight",
    path: "/:locale()/welcome/composables/useStyleWithHeaderHeight",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/composables/useStyleWithHeaderHeight.ts")
  },
  {
    name: "locale-welcome",
    path: "/:locale()/welcome",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/index.vue")
  },
  {
    name: "locale-welcome-vendors-fullpage-constants",
    path: "/:locale()/welcome/vendors/fullpage/constants",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/constants.js")
  },
  {
    name: "locale-welcome-vendors-fullpage-FullPage",
    path: "/:locale()/welcome/vendors/fullpage/FullPage",
    component: () => import("/home/ecomadm/app/releases/64/modules/rawlplug-team/pages/[locale]/welcome/vendors/fullpage/FullPage.vue")
  },
  {
    name: "page",
    path: "/:slug(.*)*",
    component: () => import("/home/ecomadm/app/releases/64/node_modules/.pnpm/@t3headless+nuxt-typo3@2.0.6_vue@3.5.12/node_modules/@t3headless/nuxt-typo3/dist/runtime/pages/T3Page.vue")
  }
]